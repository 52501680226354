:root {
  --bg-color: #546e7a;
  --bg-color-deep: #889aaa;
  --highlight-color: #8ce6cb;
  --pdng: 20px;
  --tr: 0.35s;
  --fs: 14px;
}

body {
  margin: 0;
  padding: 1rem;
  font-family: system-ui, sans-serif;
  color: black;
}

h1 {
  font-weight: 800;
  font-size: 1.5rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: var(--fs);
  font-weight: lighter;
  font-family: Manrope;
}

body,
html {
  height: 100%;
  width: 100%;
  background: var(--bg-color);
  font-family: Manrope;
  font-weight: 300;
}

body {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  padding: 0;
  margin: 0;
}

strong {
  font-weight: bold;
}

.image-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  z-index: 0;
}

.white-bg {
  background: white!important;
}

.z-index-front {
  z-index: 10;
}

.font-size-small,
.font-size-small *:not(.masonry-item-go, .masonry-item-overlay-date) {
  font-size: 16px!important;
}
.font-size-medium,
.font-size-medium *:not(.masonry-item-go, .masonry-item-overlay-date) {
  font-size: 22px!important;
}

.button {
  border: 1px solid white;
  padding: 10px 15px;
  color: white;
  display: block;
  text-decoration: none;
  /* font-size: 24px; */
  line-height: 1;
  font-size: 18px;
  letter-spacing: 1.3px;
  margin-top: 20px;
}
.button span {
  font-size: 18px;
  letter-spacing: 1.3px;
  position: relative;
  top: -2px;
}

.align-to-right {
  text-align: right;
}

.home-info {
  position: fixed;
  right: 33.33%;
  transform: translateX(50%);
}

.login-separator {
  background: white;
  height: 1px;
  margin-top: calc(var(--pdng) * 2);
}

.login {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  margin-top: var(--pdng);
  width: fit-content;
  margin-left: auto;
}
.login::before {
  width: 20px;
  height: 1px;
  background: white;
  content: '';
  position: absolute;
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
}

.contact-text {
  margin-bottom: var(--pdng);
}


.contact-popup-inner {
  position: relative;
  padding: 60px;
  padding-bottom: 40px;
  padding-top: 0;
  padding-right: 0;
}
.contact-popup-inner::after,
.contact-popup-inner::before,
.gmap-image-contact-form::before 
{
  content: '';
  position: absolute;
  background-color: white;
  display: block;
}
.contact-popup-inner::before,
.gmap-image-contact-form::before {
  bottom: 0;
  right: 0;
  width: 60%;
  height: 1px;
}
.contact-popup-inner::before {
  display:none;
}
.contact-popup-inner::after {
  top: 0;
  left: 0;
  width: 1px;
  height: 25%;
}
.straight-white-line {
  width: 60%;
  height: 1px;
  background: white;
  margin-left: auto;
  margin-top: 20px;
}
.contact-popup-inner h2 {
    font-size: 64px;
    color: var(--highlight-color);
    font-family: Mightiest;
    text-align: right;
}

.contact-popup-inner a {
    font-size: 18px;
    text-decoration: none;
    color: white;
    text-align: right;
}
.contact-popup-social {
    display: flex;
    gap: 40px;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: auto;
}
.contact-popup-social a {
    position: relative;
    line-height: 1;
    color: white;
    text-decoration: none;
    font-size: 18px;
}
.contact-popup-social a:not(:last-child):after {
    content: '';
    height: calc(100% + 5px);
    width: 1px;
    position: absolute;
    right: -19px;
    bottom: 0;
    background: var(--highlight-color);
    display: block;
}

.gmap-image-contact-form {
  max-width: 100%;
  width: 20vw;
  max-height: 30vw;
  margin-inline: auto;
  margin-top: 10px;
  position: relative;
}
.gmap-image-contact-form::before {
  top: 0;
  bottom: unset;
}

.cnt-right {
    text-align: right;
    color:white;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: var(--bg-color);
}
.navigation-dropdown-open {
  transition: var(--tr);
}
.menu-invert .navigation-dropdown-open {
  filter: invert(1);
}
.transparent {
  background: transparent;
}
.transparent .navigation-dropdown-open {
  color: white;
}
.transparent .navigation-dropdown-open svg * {
  stroke: white !important;
}
.navigation-dropdown {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -100%);
  transition: var(--tr);
  z-index: 9;
}
.navigation-dropdown.active {
  transform: translate(-50%, 0%);
}
.navigation-dropdown-open,
.navigation-dropdown-close {
  display: flex;
  gap: 10px;
  align-items: center;
  color: white;
  cursor: pointer;
}
.navigation-dropdown-close {
  margin-left: auto;
  justify-content: flex-end;
  width: fit-content;
  margin-bottom: 10px;
}
.navigation-dropdown-close::before {
  content: '';
  width: 20px;
  height: 1px;
  background: white;
}
.nav-logo {
  cursor: pointer;
}
.nav-logo svg {
  width: 140px;
}
.nav-link {
  cursor: pointer;
  padding: 10px 0;
  color: white;
  font-size: 32px;
  position: relative;
  transition: var(--tr);
  font-family: 'Mightiest';
}
.nav-link::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
}
.nav-link:hover {
  color: var(--highlight-color);
}

nav .column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin-left: 100%;
  z-index: 9;
  background: var(--bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--tr);
}
.contact-popup.active {
  margin-left: 0;
}
.bottom-right-button {
  position: absolute;
  bottom: 80px;
  right: 120px;
  color: white;
  cursor: pointer;
}
.back-to-top span,
.bottom-right-button span {
  position: relative;
  display: block;
}
.back-to-top span:not(:first-child),
.bottom-right-button span:not(:first-child) {
  margin-top: 5px;
}
.back-to-top span::before,
.bottom-right-button span::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background: white;
  right: calc(100% + 10px);
  bottom: 0;
}

.page,
.page-intro,
.page-outro {
  position: fixed;
  height: 100vh;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  width: 100%;
  color: white;
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.page {
  background-color: #889aaa;
}

.page-intro.hidden {
  opacity: 0;
  pointer-events: none;
}

.page-outro {
  transform: translateX(100%);
  z-index: 12;
}

.page-intro,
.page-outro {
  z-index: 100;
  background: var(--bg-color);
}

.page-intro .inner {
  opacity: 0;
  padding: var(--pdng);
}

.intro-page .page-outro {
  padding: 40px;
  /* transform: none; */
  transition: var(--tr);
  /* opacity: 0; */ 
}
.intro-page .page-outro.loaded {
  transform: translateX(0);
  /* transition-delay: 1s; */
}
.intro-page .page-outro.hide-outro {
  transform: translateX(0);
  opacity: 0;
  pointer-events: none;
}

.inner-intro {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 20;
  background: var(--bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: var(--tr);
  opacity: 1;
}
.inner-intro * {
  opacity: 0;
  transition: var(--tr);
  transition-delay: 0.5s;
}
.intro-main .inner-intro * {
  color: white;
}
.inner-intro.active *,
.home-page + .page-outro .inner-intro * {
  opacity: 1!important;
}
.fadein * {
  opacity: 1;
}
.fadeout {
  opacity: 0!important;
  pointer-events: none;
}
.fullwidth-image {
  transition: 0.75s;
}
body .App .no-opacity {
  opacity: 0!important;
}
.inner-intro-signature {
  position: absolute;
  bottom: var(--pdng);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.inner-intro-signature *,
.inner-intro-signature {
  font-size: 16px;
}
.inner-intro-logo {
  opacity: 1!important;
  width: 200px;
  /* opacity: 0; */
  transition: var(--tr);
}
/* .inner-intro-logo.active {
  opacity: 1;
} */
.inner-intro-logo svg {
  width: 100%;
  height: auto;
}

.quote-name {
  position: relative;
  text-align: center;
  font-size: 38px;
  font-family: Mightiest;
  width: fit-content;
  margin-inline: auto;
}
/* .quote-name::before {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: white;
} */

.content {
  font-size: xx-large;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.content::after {
  /* content: ''; */
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background: lightgray;
  height: 100%;
  width: 100%;
}

.home-page .content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  height: 100%;
  transform: none;
  z-index: 1;
  opacity: 0.3;
}

.about .content,
.about.intro .content {
  background: orange;
}

.home .content,
.home.intro .content {
  background: gray;
}

.contact .content {
  background: lightgray;
}

.intro {
  height: 100%;
  width: 100%;
  color: white;
  position: absolute;
  left: 100%;
}

.next-page-link {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;
  height: 100%;
}

.skip-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
  letter-spacing: 1.5px;
}
.skip-button::before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  height: 1px;
  width: 100%;
  background: white;

}

.masonry {
  display: flex;
  flex-wrap: wrap;
}
.masonry-item.wide {
  width: 50%;
}
.masonry-item.normal {
  width: 25%;
}
.masonry-item {
  display: flex;
  position: relative;
  transition: var(--tr);
}
.masonry-item.inactive {
  opacity: 0.45;
  pointer-events: none;
}
.masonry-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-deep);
  padding: calc(var(--pdng) * 2);
  opacity: 0;
  transition: var(--tr);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masonry-item:hover .masonry-item-overlay {
  opacity: 1;
}
.masonry-item-overlay-inner {
  padding: calc(var(--pdng) * 2);
  position: relative;
}
.masonry-item-overlay-inner::before,
.masonry-item-overlay-inner::after {
  content: '';
  position: absolute;
  background-color: white;
}
.masonry-item-overlay-inner::before {
  left: 0;
  top: 0;
  height: 50%;
  width: 1px;
}

.masonry-item-overlay-text {
  margin-bottom: calc(var(--pdng) * 2);
  font-size: 32px;
  color: var(--highlight-color);
}
.masonry-item-bottom {
  display: flex;
  justify-content: space-between;
}
.masonry-item-go {
  position: relative;
}
.masonry-item-go::before {
  content: '';
  background-color: white;
  position: absolute;
  right: 100%;
  width: 100%;
  height: 1px;
  top: 50%;
  transform: translate(-10px, -50%);
}
.masonry-item.font-size-small .masonry-item-overlay-text {
  margin-bottom: calc(var(--pdng)/2);
}
.masonry-item.font-size-medium .masonry-item-overlay-text {
  margin-bottom: var(--pdng);
}

.row {
  display: flex;
  gap: calc(var(--pdng)*2);
}

.row.half-columns > * {
  width: 50%;
}
.row.columns-55-45 > div:first-child {
  width: 55%;
}
.row.columns-55-45 > div:nth-child(2) {
  width: 45%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--pdng);
}

.topics-navigation,
.bio-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: var(--pdng);
  background-color: var(--bg-color);
  padding: var(--pdng) calc(var(--pdng) * 2 + 20px);
}
.bio-navigation {
  padding: var(--pdng) calc(var(--pdng) * 2 + 20px);
  align-items: center;
}
.topics-navigation {
  padding-inline: var(--pdng);
  justify-content: center;
  transition: var(--tr);
}
.topics-navigation.hidden {
  transform: translateY(100%);
}
.topics-navigation > .splide {
  width: 100%;
}
.topics-navigation .splide__list {
  justify-content: center;
}
.topics-navigation-link {
  height: 100%;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  transition: var(--tr);
  width: fit-content;
  white-space: pre-wrap;
  align-items: flex-end;
  display: flex;
  gap: 20px;
  margin-block: auto;
  font-size: 16px;
  font-weight: 300;
}
.topics-navigation-link::after {
  content: '';
  /* position: absolute;
  top: 50%;
  left: calc(100% + var(--pdng) / 2); */
  position: relative;
  height: 50%;
  width: 1px;
  background-color: white;
  transition: var(--tr);
}
.topics-navigation-link.active:after,
.topics-navigation-link:hover:after {
  transform: translateY(-100%);
}
.topics-navigation-link.active,
.topics-navigation-link:hover {
  color: var(--highlight-color);
}
.topics-navigation .splide__slide {
  display: flex;
  align-items: flex-end;
}
.topics-navigation .splide__arrows {
  position: absolute;
  width: calc(100% + var(--pdng) * 4 + 20px);
  left: calc(0px - var(--pdng) * 2 - 10px);
  top: 50%;
  transform: translateY(-50%);
}
.bio-navigation-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: calc(var(--pdng) * 2) var(--pdng);
}
.bio-navigation-item {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  cursor: pointer;
}
.bio-navigation-item div {
  white-space: nowrap;
}
.bio-navigation-item .date {
  font-size: 32px;
  font-family: Mightiest;
}
.bio-image {
  width: 75%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}
.bio-image img {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bio-info {
  padding: var(--pdng);
  width: 25%;
  background: white;
  height: 100%;
  max-height: 100%;
  display: flex;
  transition: calc(var(--tr) * 2);
  position: relative;
}
.bio-info.active {
  width: 66.66%;
}
.bio.content {
  display: flex;
}
.bio-info * {
  color: black;
}
.bio-info .initial-content {
  transition: var(--tr);
  position: fixed;
  /* width: calc(25% - var(--pdng)*2); */
  width: 25%;
  top: 0;
  display: block;
  min-width: 0!important;
}
.bio-info.active .bio-drawer {
  transform: translateX(0%);
}
.bio-drawer {
  position: fixed;
  right: 0;
  width: calc(25% - var(--pdng)*2);
  height: 100%;
  transform: translateX(100%);
  background: var(--bg-color-deep);
  padding: var(--pdng);
  transition: calc(var(--tr) * 2);
  opacity: 1!important;
  overflow-y: auto;
}
.bio-drawer.active {
  /* width: 50%; */
  opacity: 1;
}
.bio-drawer-section.active {
  opacity: 1;
  transform: translateX(0);
  overflow-y: visible!important;
}
.bio-drawer-section:not(.active) {
  overflow-y: hidden!important;
  height: 0!important;
}
.bio-drawer-section {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  padding: var(--pdng);
  /* transition: var(--tr); */
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--pdng);
}

.bio-drawer-title {
  font-size: 26px;
  color: var(--bg-color);
  /* padding-top: calc(var(--pdng) * 2); */
}
.bio-drawer-subtitle {
  font-size: 16px;
  color: #37474F;
}
.bio-drawer-subtitle-small {
  font-size: 12px;
  color: white;
}
.bio-drawer-text {
  color: #37474F;
  font-size: 18px;
  margin-top: auto;
}

.bio-navigation-previous,
.bio-navigation-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--tr);
  opacity: 0;
  pointer-events: none;
}

.bio-navigation-previous {
  left: 10px;
}
.bio-navigation-previous.active,
.bio-navigation-next.active {
  opacity: 1;
  pointer-events: all;
}
.bio-navigation-next {
  right: 10px;
}

.bio-navigation > .splide {
  width: 100%;
}

.bio-navigation .splide__arrows {
  position: absolute;
  width: calc(100% + var(--pdng) * 2 + 40px );
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(calc(0px - var(--pdng) - 20px));
}

.video-player {
  transition: var(--tr);
}
.video-player iframe {
  width: 100%;
  height: 100%;
}
.video-player > div {
  position: fixed;
  left: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
}

.topic-intro-title {
  font-family: 'Mightiest';
  font-size: 73px;
  color: #8ce6cb;
  text-align: left;
  font-weight: 300;
  padding: var(--pdng) calc(var(--pdng) * 2);
  line-height: 1;
  position: relative;
}

.topic-intro-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 70%;
  background-color: white;
  transform: translateY(-10%);
}

.topic-intro-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  width: 60%;
  /* transform: translateX(40%); */
}

.page-intro .inner h2,
.page-outro .inner h2 {
  font-size: 35px;
  text-align: center;
  max-width: 768px;
  font-family: 'Manrope';
  font-weight: 300;
  margin-bottom: var(--pdng);
  line-height: 1.2;
}

/* quote {
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 38px;
  text-align: center;
  display: block;
  border-top: 1px solid white;
  line-height: 2;
  width: fit-content;
  margin-inline: auto;
} */

.content h2 {
  font-family: Mightiest;
  font-size: 57px;
  line-height: 1.2;
  text-align: center;
  margin-top: -50px;
  /* padding-bottom: 20px; */
  /* border-bottom: 2px solid white; */
}

.contact-button-fixed {
  position: fixed;
  top: 50%;
  right: calc(var(--pdng) * 2);
  transform: translateY(-50%);
  margin-top: -20px;
  color: white;
  font-size: 18px;
  z-index: 3;
  font-weight: 500;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

/* BIO */

.bio-info {
  padding: 0 !important;
}

.bio-info h2 {
  font-size: 72px;
  font-family: 'Mightiest';
  font-weight: 400;
  padding-top: calc(var(--pdng) * 4);
  color: #37474f;
  text-align: left !important;
}

.bio-info div {
  font-family: 'Manrope';
  /* max-width: 346px; */
  text-align: left;
  font-weight: 400;
}

.info-text {
  padding-bottom: 49px;
  font-size: 26px;
  max-width: 346px;
  font-weight: 500 !important;
}

.bio-info .initial-content {
  padding: var(--pdng);
  overflow-y: auto;
}
.bio-info .initial-content::-webkit-scrollbar {
  width: 0;
}

.bio-info .initial-content-subtitle {
  /* font-size: 26px; */
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: calc(var(--pdng)*2);
}

.initial-content h2 {
  margin-bottom: calc(var(--pdng)*2);
}

.initial-content-list {
  display: flex;
  flex-direction: column;
  gap: var(--pdng);
  /* position: absolute; */
  bottom: var(--pdng);
  width: calc(100% - var(--pdng) * 2);
}
.initial-content-list div {
  color: var(--bg-color);
  font-size: 18px;
}

.personal-info div {
  font-size: 20px;
  color: #37474f;
}

.personal-info {
  max-width: 380px;
  /* padding-right: 38px; */
}

.info-sets {
  padding-bottom: 29px;
}

.initial-content {
  min-width: 50% !important;
}

.initial-content-right {
  background-color: var(--bg-color-deep);
  font-family: 'Manrope';
  padding-left: 45px;
  min-width: 50% !important;
}

.info-text-right {
  position: absolute;
  bottom: 10%;
  font-weight: 500 !important;
}

.bio-navigation-item-mobile-content {
  background-color: white;
  color: var(--bg-color);
  padding: 20px;
}
.bio-navigation-item-mobile-content * {
  font-size: 18px;
}
.bio-navigation-item-mobile-clickable {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
}
.bio-navigation-item-mobile-clickable .date {
  font-size: 40px;
  font-family: 'Mightiest';
}
.bio-navigation-item-mobile-clickable div {
  font-size: 14px;
}
.bio-navigation-item-mobile-clickable {
  padding-bottom: 60px;
  position: relative;
}
.bio-navigation-item-mobile-clickable div {
  font-size: 14px;
}
.bio-navigation-item-mobile-clickable .date::after {
  content: '';
  display: block;
  position: absolute;
  background: white;
  width: 2px;
  height: 60px;
  bottom: 0;
}
.bio-navigation-item-mobile:not(.active) .bio-navigation-item-mobile-content {
  display: none;
}
.bio-navigation-item-mobile-close {
  width: fit-content;
  margin-top: 20px;
}

/* .bio-image {
  width: 50% !important;
} */

/* .bio-image img {
  left: 80% !important;
} */

/* .bio-info {
  width: 50% !important;
} */

.initial-content-right h3 {
  font-size: 30px;
  color: #546e7a;
  text-align: left;
  margin-top: 158px;
  font-family: 'Manrope';
  font-weight: 400;
  line-height: 1;
}

.initial-content-right h4 {
  font-size: 21px;
  color: #37474f;
  text-align: left;
  padding-top: 25px;
  font-weight: 500;
}

.initial-content-right p {
  font-size: 16px;
  color: white;
  text-align: left;
  font-weight: 500;
}

.info-text-right {
  font-size: 21px;
  color: #37474f;
  text-align: left;
  max-width: 407px;
}

.after-video {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  text-align: center;
  background: var(--bg-color);
  margin-top: -20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--tr);
}
.after-video:not(.active) {
  opacity: 0;
  pointer-events: none;
}
.after-video h2 {
  font-size: 46px;
  /* border-bottom: 1px solid white; */
  padding-bottom: var(--pdng);
  font-family: Mightiest;
}

.mute-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  cursor: pointer;
}
.mute-button span {
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  transform: rotate(45deg) translateX(-60%) translateY(-50%);
  left: 30%;
  top: 85%;
  display: block;
}

/* MENU */

.nav-link {
  width: 298px;
  font-size: 60px !important;
  padding-block: 10px !important;
}

.navigation-dropdown nav .row {
  gap: 160px !important;
}

/* TOPICS OUTRO */

.outro-content {
  font-size: 57px;
  color: white;
  font-family: 'Mightiest';
}

.outro-content::after {
  content: ''; /* Add content to the pseudo-element */
  position: absolute;
  bottom: 40%; /* Adjust the percentage to vertically center the line */
  left: 50%;
  transform: translate(-50%, 0); /* Center the line horizontally */
  width: 365px; /* Adjust the width as needed */
  height: 1px; /* Adjust the height as needed */
  background-color: white; /* Change the color as needed */
}

/* MOODBOARD */
.moodboard-page {
  background: white !important;
}
.moodboard-content {
  margin-top: 40px;
  height: calc(100% - 80px);
  padding: 40px;
  overflow-y: auto;
  gap: 40px;
  background: white!important;
}
.moodboard-content > div {
  width: 100%;
  height: 100%;
}
.moodboard-options {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}
.masonry-menu-item {
  cursor: pointer;
  font-size: 24px;
  color: #c3c7ca;
  transition: var(--tr);
  line-height: 1!important;
}
.masonry-menu-item.active,
.masonry-menu-item:hover {
  color: var(--bg-color);
}
.masonry-menu {
  font-family: 'Manrope';
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 0;
  gap: 20px;
}
.masonry-menu div {
  font-family: 'Manrope', sans-serif;
  font-size: 55px;
  line-height: 1.5;
}

.moodboard-options{
  justify-content: flex-end!important;
  gap: 300px;
}


.masonry-content h2 {
  line-height: 1.5;
  font-size: 64px;
  color: #37474f;
  border-bottom: 1px solid #37474f;
  line-height: 1;
  text-align: left;
  padding-bottom: 20px;
}
.masonry-content p {
  font-family: 'Manrope';
  font-weight: 500;
  font-size: 20px;
  color: #37474f;
  text-align: left;
  padding-top: 20px;
}
.masonry-column {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.masonry-column img {
  width: 100%;
  height: auto;
}

/* ARTICLE */
.article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 120px;
  overflow-y: auto;
}
.article * {
  color: black;
}
.article h1 {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: calc(var(--pdng));
}
.article-content img,
.article-featured-image img {
  width: 100%;
  height: auto;
}
.article-featured-image {
  margin-top: calc(var(--pdng) * 2);
}
.article-content {
  margin-top: calc( var(--pdng) * 4 );
}
.article-content * {
  font-size: 16px;
}
.article-content img {
  margin-top: auto;
}
.article-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-page-back {
  cursor: pointer;
}

/* IMAGE BANNER */
.image-banner {
  position: relative;
}
.image-banner img {
  width: 100%;
  height: auto;
}
.image-banner-text {
  position: absolute;
  bottom: var(--pdng);
  left: var(--pdng);
  width: calc(100% - var(--pdng) * 4);
  background: white;
  color: black;
  padding: var(--pdng);
  font-size: 18px;
  font-weight: 500;
  max-width: 75%;
}

/* FOOTER TOPICS */

.topics-navigation {
  height: 90px;
}

.bio-navigation-item {
  font-family: 'Manrope';
  font-weight: 300;
}


.back-to-top {
  position: fixed;
  right: 80px;
  bottom: 80px;
  z-index: 5;
  cursor: pointer;
  color: black;
  opacity: 0;
  pointer-events: none;
  transition: var(--tr);
}
.back-to-top span {
  font-weight: 500;
}
.back-to-top span::before {
  background-color: black;
}
.back-to-top.active {
  opacity: 1;
  pointer-events: all;
}

.menu-bottom-left-links {
  position: absolute;
  left: var(--pdng);
  bottom: var(--pdng);
  display: flex;
  gap: var(--pdng);
}
.menu-small-link {
  color: white;
  font-weight: normal;
  cursor: pointer;
}

.menu-small-link .nav-link{
  all: unset!important;
}

.menu-small-link .nav-link::after{
  all: unset!important;
}
/* Privacy Page */
.privacy-row,
.terms-row{
  display: flex;
  flex-wrap: wrap;
  
}

.privacy-row p,
.terms-row p{
  padding-bottom: 20px;
  font-size: 1.1rem;
}

.privacy-head,
.terms-head{
  font-family: Mightiest!important;
  font-size: 5rem;
  padding-bottom: 50px;
  border-left: 1px ridge #000;
  margin-left: 25px;
  padding-left: 20px;
  color: #000!important;
}

.privacy-col-md-6,
.terms-col-md-6{
  flex: 1;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
}

.privacy-policy-page,
.terms-policy-page{
  background: #fff;
}

.privacy-policy-page p,
.privacy-policy-page h3,
.terms-policy-page p{
  color: #000!important;
  font-weight: 400;
  line-height: 1.7;
}

@media (max-width: 750px){

  .privacy-head,
  .terms-head{
    margin-top: 100px;
  }
  .privacy-col-md-6,
  .terms-col-md-6{
    flex: auto;
  }
}

/* non-fixed-menu */
.non-fixed-menu{
  position: relative;
}

.privacy-policy-page{
  position: relative!important;
  overflow: scroll;
}

/* Cookie banner */

.cookie-consent-banner{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #546E7A!important;
  padding: 50px;
  max-width: 500px;
}

.cookie-consent-banner p{
  color: #fff!important;
  padding-bottom: 20px;
}

.cookie-consent-banner button{
  padding: 10px!important;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  color: #fff;
  background: none!important;
  border: none;
}

.cookie-consent-banner button:before{
  content: "";
  position:absolute;
  border-top: 1px ridge #fff;
  top: 0;
  left: 0;
  z-index: 999;
}

.cookie-consent-banner  .cookie-title{
  font-family: 'Manrope'!important;
  padding-bottom: 20px!important;
  font-size: 24px!important;
  color: #fff;
}




@media (max-width: 1500px) {
  .topics-navigation .splide__list {
    justify-content: unset;
  }
}


@media (min-width: 1201px) {
  .mobile-element {
    display: none;
  }
}


@media (max-width: 1200px) {
  .content.bio {
    overflow-x: hidden;
  }
  .bio-info h2 {
    margin-top: -80px;
  }
  .initial-content h2 {
    margin-bottom: var(--pdng);
  }
  .desktop-element {
    display: none;
  }
  .moodboard-options {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .moodboard-options h2 {
    font-size: 48px!important;
    width: fit-content;
    padding-bottom: 10px;
  }
  .moodboard-content {
    margin-top: 80px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .moodboard-content > div {
    height: unset;
  }
  .masonry-content p {
    font-size: 25px;
    line-height: 1.2;
  }
  .masonry-item.inactive {
    display: none;
  }
  .masonry-item-overlay-text {
    font-size: 18px!important;
  }
  .masonry-menu {
    width: 100%;
    gap: 10px;
  }
  .masonry-menu div {
    font-size: 38px;
  }
  .font-size-medium, 
  .font-size-medium *:not(.masonry-item-go, .masonry-item-overlay-date) {
    font-size: 18px!important;
  }
  .navigation-dropdown nav .row,
  .navigation-dropdown nav .row .column {
    flex-direction: column;
    gap: 0!important;
  }
  .nav-link {
    font-size: 38px!important;
    width: 100%;
  }
  .menu-bottom-left-links {
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .menu-bottom-left-links,
  .contact-popup-social {
    width: 100%;
    justify-content: space-between;
  }
  .navigation-dropdown nav,
  .menu-bottom-left-links {
    width: 100%;
    padding-inline: 60px;
  }
  .menu-small-link {
    color: #AFB5B9;
  }
  .contact-popup-social a:not(:last-child):after {
    display: none;
  }
  .contact-popup-social a {
    font-size: 14px;
  }
  .bio.content {
    display: block;
    overflow-y: auto;
  }
  .bio-image,
  .bio-info  {
    width: 100%;
  }
  .bio-image {
    height: auto;
  }
  .bio-image img {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: auto;
    min-height: auto;
  }
  .bio-info {
    position: relative;
    margin-top: 0!important;
    max-height: unset;
    height: fit-content!important;
    transition: none;
  }
  .bio-info.active,
  .bio-info .initial-content,
  .bio-drawer {
    width: 100%;
  }
  .bio-info .initial-content {
    position: relative;
    height: fit-content!important;
  }
  .initial-content-list {
    position: relative;
  }

  .menu-invert .navigation-dropdown-open {
    filter: none;
  }

  .bio-navigation-item div {
    white-space: wrap;
  }

  .bio-drawer-text {
    margin-top: 0;
  }

  .topic-intro-title {
    font-size: 48px;
  }
  .App:not(.services-page) .contact-button-fixed {
    top: unset;
    bottom: 40px;
    right: 50%;
    transform: translateX(50%) translateY(50%);
  }
  .contact-popup-inner {
    padding: 30px;
    padding-bottom: 30px;
    padding-right: 0;
    padding-top: 0;
    text-align: right;
  }  
  .contact-popup-inner h2 {
    font-size: 48px;
  }
  .contact-popup-inner a {
    font-size: 14px;
  }
  .contact-popup-social a {
    font-size: 14px;
  }
  .contact-popup .contact-popup-social {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
  }
  .contact-popup-inner::before {
    width: 70%;
  }

  .gmap-image-contact-form {
    max-width: 100%;
    width: 80vw;
    max-height: 70vw;
    margin-inline: auto;
    margin-top: 10px;
    position: relative;
  }

  .bio-navigation-container {
    display: none;
  }

  .back-to-top {
    right: 40px;
    bottom: 40px;
  }

}


@media (max-width: 768px) {

  .home-info {
    right: 50%;
  }

  .App.services-page .contact-button-fixed {
    top: unset;
    bottom: 40px;
    right: 50%;
    transform: translateX(50%) translateY(50%);
    transition: var(--tr);
  }
  .App.services-page.video-ended .contact-button-fixed {
    bottom: 120px!important;
  }
  .row {
    flex-direction: column;
  }
  .column,
  .row.columns-55-45 > div:first-child,
  .row.columns-55-45 > div:nth-child(2),
  .image-banner-text {
    width: 100%!important;
  }
  .image-banner-text {
    max-width: calc(100% - var(--pdng) * 2);
  }
  .article,
  .moodboard-content {
    padding: 20px;
  }
  .article {
    padding-top: 100px;
  }
  .moodboard-content {
    padding-top: 40px!important;
  }
  .moodboard-content,
  .masonry-column,
  .masonry-column > div:not(.moodboard-options) > div,
  .masonry-column > div:not(.moodboard-options) > div > div {
    gap: 20px!important;
  }

  .quote-name {
    font-size: 26px;
  }

  .bottom-right-button {
    bottom: 40px;
    right: 40px;
  }

  .contact-popup-inner {
    width: calc( 100% - var(--pdng) * 4 );
  } 

}

@media (max-width: 750px) {
  .cookie-consent-banner{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: #546E7A!important;
    padding: 50px;
    max-width: 100%;
  }
}



@media (max-width: 600px) {
  .mute-button {
    right: unset;
    left: 20px;
  }
  .page-intro .inner h2, 
  .page-outro .inner h2 {
    font-size: 20px;
  }
  .topics-navigation-link {
    font-size: 16px;
  }
  .content h2,
  .after-video h2 {
    font-size: 30px;
  }
  
}


@media (max-width: 500px) {
  .contact-popup-inner > a {
    font-size: 20px;
  }
}



.video-intro {
  transition: calc(var(--tr) * 3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.video-intro:not(.hide-intro) .page-intro {
  opacity: 1!important;
  pointer-events: all!important;
}
.hide-intro {
  opacity: 0!important;
  pointer-events: none!important;
}


#frontend_login_input {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  color: white;
  text-align: left;
  padding-right: 22px;
}

.message-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  z-index: 1000;
  transition: 0.35s;
}
.message-popup.active {
  opacity: 1;
  pointer-events: all;
}
.message-popup-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: black;
  opacity: 0.4;
}
.message-popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: white;
  color: black;
  padding: var(--pdng) calc(var(--pdng) * 2);
  width: 100%;
  max-width: 350px;
}
.message-popup-content h2 {
  font-family: 'Manrope'!important;
  font-size: 20px!important;
  margin-top: 0!important;
}
.message-popup-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.message-popup-close svg {
  width: 100%;
  height: 100%;
}

.home-input-container {
  position: relative;
  margin-top: var(--pdng);
}
.home-input-container.viewing .password-view,
.home-input-container:not(.viewing) .password-hide {
  display: none;
}
.password-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
}
.password-button svg {
  width: 20px;
  height: 20px;
}


.fullwidth-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .fullwidth-image.desktop-element {
    display: none!important;
  }
  .fullwidth-image.mobile-element {
    display: block!important;
  }
}
@media (min-width: 769px) {
  .fullwidth-image.mobile-element {
    display: none!important;
  }
  .fullwidth-image.desktop-element {
    display: block!important;
  }
}